@import 'assets/styles/mixins';

.item {
    @include d-flex(row, unset, center, var(--spacing-2));
    padding: var(--spacing-2) var(--spacing-3);
    border-radius: var(--spacing-2);
    border: 1px solid transparent;
    background-color: var(--color-neutral-50);

    &.active {
        border-color: var(--color-primary-300);
    }

    &__icon {
        width: 24px;
        height: 24px;
        flex-shrink: 0;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__details {
        flex-grow: 1;
    }

    &__pan {
        flex-shrink: 0;
    }
}
