@import 'assets/styles/mixins';

.anchor {
	@include d-flex(row, unset, center);
    padding: 0 var(--spacing-3) 0 0;
    background-color: var(--color-neutral-50);
    border-radius: var(--spacing-2);
    border: 1px solid var(--color-primary-300);

    .preview {
        flex-grow: 1;
        padding-right: var(--spacing-2);
        background-color: transparent
    }

    .arrow {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
    }
}
