@import 'assets/styles/mixins';

.wrapper {
    padding: 0;
    border: 1px solid var(--color-primary-300);
    border-radius: var(--spacing-1);
    padding-right: var(--spacing-3);
    
    .tooltip {
        @include d-flex(row, unset, center);
        width: 100%;
    }

    .content {
        flex-grow: 1;
    }

    .arrow {
        flex-shrink: 0;
        flex-grow: 0;
        transform: rotate(-90deg);
    }
}
