@import 'assets/styles/mixins';
@import 'assets/styles/media';

.modal {
	&.hide {
		&::part(content) {
			visibility: hidden;
		}

		&.mobile {
			.apple__fee {
				display: none;
			}
		}
	}
}

.content {
	@include d-flex(column);
	gap: var(--spacing-3);
	margin-top: var(--spacing-5);

	.detail {
		@include d-flex(row, space-between, center, var(--spacing-3));
	}

	.list__item {
		user-select: none;
		padding: 0;

		&.disabled {
			opacity: 0.5;
		}

		.tooltip {
			width: 100%;
		}
	}

	.apple__fee {
		gap: var(--spacing-2);
	}

	.button_gPay {
		width: 100%;

		button {
			width: 100%;
			outline: none !important;
		}
	}

	.create-host-card {
		text-decoration: underline;
		cursor: pointer;
	}
}

apple-pay-button {
	--apple-pay-button-width: 100%;
	--apple-pay-button-height: var(--button-height);
	--apple-pay-button-border-radius: var(--button-border-radius);
	--apple-pay-button-padding: 0px 0px;
	--apple-pay-button-box-sizing: border-box;
}

.divider {
	text-align: center;
	display: flex;
	align-items: center;
	gap: var(--spacing-2);
	color: var(--color-neutral-500);
	margin-bottom: var(--spacing-3);
	margin-top: var(--spacing-3);

	&::before,
	&::after {
		content: '';
		width: 100%;
		height: 2px;
		background-color: #ccc;
	}

	@include mobile {
		margin-bottom: 6px;
		margin-top: 6px;
	}
}
