@import 'assets/styles/mixins';
@import 'assets/styles/media.scss';

.wrapper {
	background-color: var(--color-neutral-white);
	
	&.full-layout {
		grid-template-rows: 100px 1fr 104px;

		.content {
			padding-top: 0;
		}
	}

	.content {
		&_webLogin {
			max-width: none;
		}

		&.content-lg {
			max-width: 684px;
		}
	}

	@include mobile {
		&.full-layout {
			grid-template-rows: 1fr auto;
			overflow-y: auto;
			height: 100%;

			.content {
				padding-top: env(safe-area-inset-top);
				height: max-content;
			}
		}

		.content {
			position: relative;
			height: 100%;

			&:not(&.content-lg) {
				overflow: hidden;
			}

			& ion-button:last-of-type {
				margin-top: auto;
			}

			&_webLogin {
				overflow-y: auto;
			}
		}
	}
}
