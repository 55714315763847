@import 'assets/styles/media.scss';
@import 'assets/styles/mixins.scss';

.contacts {
	@include d-flex(row);
	gap: var(--spacing-2);

	li {
		@include d-flex(row);

		span {
			font-weight: 700;
		}
	}
}

@media (max-width: 600px) {
    .contacts {
        flex-direction: column;

        span {
            display: none;
        }
    }
}
