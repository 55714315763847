@import 'assets/styles/mixins';

.wrapper {
	@include d-flex(row, space-between);
	width: 100%;
}

.accordion {
	&:not(.disabled) &__header {
		border: 1px solid var(--color-primary-100);
	}

	&.disabled {
		background-color: var(---color-neutral-white);
	}

	.service {
		padding-top: 0;
	}
}
