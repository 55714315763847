@import 'assets/styles/mixins';

.content {
	@include d-flex(column);
	height: 100%;
	overflow: auto;
	overflow-x: clip;
	padding-right: var(--spacing-2);
	z-index: 1;
	color: var(--color-neutral-white);

	* {
		color: var(--color-neutral-white);
	}

	.row {
		&_amount {
			@include d-flex(row, flex-start, center, var(--spacing-3));
			justify-content: space-between;

			&.own_credit {
				margin-right: calc(var(--spacing-3) + var(--spacing-6));
			}

			&.credit > p {
				font-weight: 700;
			}
		}
	}

	.arrow {
		transform: rotate(-90deg);

		path {
			stroke: var(--color-neutral-white);
		}
	}

	&.multicard {
		* {
			color: var(--color-primary-800);
			text-shadow: 1px 1px var(--color-primary-50);
		}

		.arrow {
			path {
				stroke: var(--color-primary-800);
			}
		}
	}
}
