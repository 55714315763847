@import 'assets/styles/mixins';

div.content {
	@include d-flex(column, flex-end, flex-start);
	width: 100%;
	height: 100%;
	background-color: var(--color-primary-500);
	position: relative;
	z-index: 1;

	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 36px;
		top: var(--spacing-6);
		left: 0;
		background-color: #cacccb;
		background-image: url('~assets/icons/card-magnet-pattern.svg');
		background-size: auto 50%; /* Adjust the height to create a gap */
		background-position: left center;
		background-repeat: repeat-x;
		background-size: 76px 56%, 76px 56%; /* Total 56px image width + 20px gap = 76px */
		z-index: -1;
	}

	&__row {
		@include d-flex(row, space-between, center, var(--spacing-6));
		width: 100%;
		margin-top: var(--spacing-4);

		&:first-of-type {
			margin-top: 0;
		}
	}

	.cvv {
		@include d-flex(row, flex-start, center);
		min-width: 60px;

		.loader {
			width: 26px;
			height: 18px;
			margin-left: 4px;
		}
	}

	&.multicard {
		border: 0.2px solid var(--color-primary-300);
		background-color: rgb(255, 255, 254);

		* {
			color: var(--color-primary-800)
		}
	}
}
