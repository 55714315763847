@import 'assets/styles/mixins.scss';
@import 'assets/styles/media.scss';

header.wrapper {
	@include d-flex(row, flex-start, center, var(--spacing-2));
	width: 100%;

	& > div {
		@include d-flex(column, flex-start, center, var(--spacing-2));
		width: 100%;

		img {
			height: 64px;
			aspect-ratio: 1 / 1;
			object-fit: contain;
		}

		.title {
			display: block;
			width: 100%;
			text-align: center;
		}
	}

	.button {
		&_back {
			align-self: flex-start;
		}
	}

	@include mobile {
		& > div:first-child {
			margin-bottom: var(--spacing-3);
		}
	}
}
