.disabled {
    opacity: 0.3;
}

.input {
    padding-right: 36px !important;
}

.spinner {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translate(0, -50%);
}

.autocomplete {
    &:has(.arrow) {
        &:global(.Mui-expanded svg) {
            transform: translate(0, -50%) rotate(-180deg);
        }
    }
}