@import 'assets/styles/media.scss';
@import 'assets/styles/mixins.scss';

.footer {
    background-color: var(--color-primary-500);
	padding: var(--spacing-6) var(--spacing-7);
    color: var(--color-neutral-white);

    a {
        font-weight: 700;
    }

    .content {
        max-width: 1024px;
        margin: 0 auto;
        @include d-flex(row, space-between, flex-start, var(--spacing-4))
    }

    @include mobile {
        .content {
            flex-wrap: wrap;
        }
    }

    @media (max-width: 600px) {
        .content {
            flex-direction: column;
            align-content: center;
        }
    }
}
