@import 'assets/styles/mixins.scss';

.header {
    @include d-flex(row, space-between, unset, var(--spacing-3));
    width: 100%;

    &:not(:first-of-type) {
        margin-top: var(--spacing-6);
    }
}
