@import 'assets/styles/mixins.scss';

.wrapper {
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 1fr;
	gap: var(--spacing-3);
}

@include tablet-sm {
	.wrapper {
		grid-template-columns: 1fr;
	}
}

.label {
	margin-bottom: var(--spacing-3);
}

.item {
	padding: var(--spacing-2);
	border-radius: var(--spacing-2);
	border: 1px solid transparent;
	width: 100%;
    align-items: center;

	& > span:not(:first-of-type) {
		width: 100%;
	}

	&.selected {
		border: 1px solid;
	}
}
