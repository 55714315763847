@import 'assets/styles/mixins.scss';

.wrapper {
	box-shadow: none;

	&.disabled {
		border-radius: var(--spacing-2);
	}

	&::before {
		display: none;
	}

	.header {
		background: var(--color-primary-50);
	}

	.details {
		border-radius: var(--spacing-2);
		border: 1px solid var(--color-primary-100);
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-top: none;
	}
}
