@import 'assets/styles/mixins';

.content {
	@include d-flex(column);
	max-width: 480px;
	width: 100%;
	// possibly need to try fit-content
	height: 100%;
	padding-bottom: var(--spacing-1);
}

.blocked {
	opacity: 0.7;
}

.operations {
	padding: 0 var(--spacing-6);
}
