@import 'assets/styles/media';

.slider {
	display: grid;
	overflow-x: scroll;
	scroll-snap-type: x mandatory;
	height: 100%;
	padding-left: var(--spacing-6);
	padding-right: var(--spacing-6);
	padding-top: var(--spacing-3);
	padding-bottom: var(--spacing-3);
	gap: var(--spacing-4);
	grid-template-columns: repeat(var(--cards-count), 92%);

	&:has(.slider__item--single) {
		grid-template-columns: 100%;
	}

	&__two-cards {
		gap: var(--spacing-4);
	}

	&::-webkit-scrollbar {
		display: none;
	}

	&__item {
		scroll-snap-align: center;
		scroll-snap-stop: always;
		height: 100%;
		width: 100%;
		flex-shrink: 0;

		&--single {
			width: 100%;
			padding-left: 0;
			padding-right: 0;
		}
	}

	&__spacer {
		display: none;
	}

	@include mobile-small-height {
		&__spacer {
			display: block;
			width: 1px;
			padding-right: 1px;
		}
	}
}
