.modal {
    --backdrop-opacity: 0 !important;
}

.content {
    display: grid;
    gap: var(--spacing-2);

    .another-card {
        margin-top: var(--spacing-3);
    }
}
