@import 'assets/styles/mixins';
@import 'assets/styles/media';

.content {
	@include d-flex(column, null, null, 24px);
	text-align: left;

	& ion-button,
	& > div:nth-of-type(3) {
		margin-top: auto;
	}
}
