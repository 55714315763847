.item {
	user-select: none;
	padding: 0;
	width: 100%;
	border: 1px solid var(--color-primary-300);
	border-radius: var(--spacing-1);

	&.disabled {
		cursor: default;
	}
}
